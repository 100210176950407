import React, {useEffect, useState} from 'react';
import '../style/Home.css';
import {useSetRecoilState} from "recoil";
import {isLoading, countryState} from "../api/Atoms";
import {getAllCoupons} from "../api/Api";
import Coupon from "./Coupon";
import 'swiper/css';
import 'swiper/css/navigation';
import Slider from "./Slider";
import {useNavigate} from "react-router-dom";
import { ModalProvider, useModal } from './ModalContext';

const Modal = () => {
    const { modalContent, closeModal } = useModal();

    if (!modalContent) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div onClick={closeModal} style={{position: 'absolute', top: '10px', left: '10px', width: 'auto', cursor: 'pointer'}}>X</div>
                {modalContent}
            </div>
        </div>
    );
};

const Home = () => {

    const [coupons, setCoupons] = useState([]);
    const [topCoupons, setTopCoupons] = useState([]);
    const [rawData, setRawData] = useState([]);
    const [brands, setBrands] = useState([]);
    const [country, setCountry] = useState('us');
    const [skip, setSkip] = useState(10)
    const setLoadingState = useSetRecoilState(isLoading)
    const setCountryState = useSetRecoilState(countryState)
    const navigate = useNavigate();
    const counties = ['us','uk','de','fr'];

    useEffect( () => {
        setLoadingState(true);
        getAllCoupons(country).then((data) => {
            data.merchants.forEach((result) => {
                    const discount = extractDiscount(result.title);
                    result.discount = discount;
            })
            data.merchants = data.merchants.filter((result) => result.discount !== null);
            let randomIndexes = getRandomIndexes(data.merchants.length);
            data.merchants = randomIndexes.map((index) => data.merchants[index]);
            setRawData(data.merchants);
            setCoupons(data.merchants.slice(skip, skip + 20));
            setTopCoupons(extractCouponsByBrand(data.merchants));
            setSkip(30);
            setLoadingState(false);
            setBrands(extractBrands(data.merchants));
        });
    }, [country]);

    function getRandomIndexes(length) {
        const indexes = Array.from({ length }, (_, i) => i);
        for (let i = length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [indexes[i], indexes[j]] = [indexes[j], indexes[i]];
        }
        return indexes;
    }

    function extractDiscount(title) {
        const percentageMatch = title.match(/(\d+(?:\.\d+)?)%\s*(?:Off|Discount)/i);
        if (percentageMatch) {
            return `${percentageMatch[1]}%`;
        }
        const dollarMatch = title.match(/\$(\d+(?:\.\d+)?)/);
        if (dollarMatch) {
            return `$${dollarMatch[1]}`;
        }
        const upToMatch = title.match(/Up to (\d+(?:\.\d+)?)%/i);
        if (upToMatch) {
            return `Up to ${upToMatch[1]}%`;
        }
        return null;
    }

    const extractCouponsByBrand = (data) => {
        const brands = [];
        const coupons = [];
        data.forEach((result) => {
            if (coupons.length > 10) {
                return;
            }
            if (!(brands.includes(result.merchant_name))) {
                brands.push(result.merchant_name)
                coupons.push(result);
            }
        });
        return coupons;
    }

    const extractBrands = (data) => {
        const brands = [];
        data.slice(0,300).map((result) => {
            if (!Object.keys(brands).includes(result.merchant_name)) {
                brands[result.merchant_name] = result.logo;
            }
        })
        const firstEntries = Object.entries(brands).slice(0, 10);
        const firstBrands = Object.fromEntries(firstEntries);
        return firstBrands;
    }

    const goTo = (brand) => {
        const path = brand.toLowerCase().replace(/ /g, "-");
        navigate("/brand/" + path, { state: { coupons: rawData, brand: brand } });
    }

    const loadMore = () => {
        setLoadingState(true);
        const newCoupons = rawData.slice(skip, skip + 20);
        setCoupons(prevState => [...prevState, ...newCoupons])
        setSkip(skip + 20)
        setLoadingState(false);
    }

    const setChosenCountry = (country) => {
        setCountry(country);
        setCountryState(country);
    }

    return (
        <ModalProvider>
            <div className="Home-Container">
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
                <div className="Flags-Container">
                    {
                        counties.map((result, index) => (
                            <img className={country === result? "Current-Flag": ""} key={index} alt={result} src={`/assets/${result}-flag.png`} onClick={() => setChosenCountry(result)}/>
                        ))
                    }
                </div>
                <h1>Today's Top Coupons</h1>
                <Slider coupons={topCoupons}/>
                <h1>Today's Top Brands</h1>
                <div className="Brands-Container">
                    {Object.keys(brands).map((result, index) => (
                        <div key={index} className="Brand-Container" onClick={() => goTo(`${result}`)}>
                            <div className="Image-Container-Brand">
                                <img src={brands[result]} alt={result}/>
                            </div>
                            <div className="Brand-Title-Container">
                                <p>{result}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <h1>Today's Trending Coupons</h1>
                <div className='Home'>
                    {coupons.map((result, index) => (
                        <Coupon key={index} coupon={result}/>
                    ))}
                </div>
                <button className="Load-More" onClick={() => loadMore()}>Load More Coupons</button>
            </div>
            <Modal/>
        </ModalProvider>
    );
};

export default Home;
