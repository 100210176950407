import React from 'react';
import LoadGif from '../style/load.gif';
import '../style/Coupon.css';
import { useModal } from './ModalContext';

const Coupon = ({ coupon }) => {
    const imageRef = React.useRef(null);
    const { openModal } = useModal();
    const handleImageError = (e) => {
        e.target.onerror = null;
        e.target.src = LoadGif;
    };

    function calculateRemainingDays(startDateStr, endDateStr) {
        const now = new Date();
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);
        if (now < startDate) {
            return "Event has not started";
        }
        if (endDateStr === 'None' || endDateStr === 'none') {
            return "Event started!";
        } else {
            const timeLeft = endDate - now;
            if (timeLeft < 0) {
                return "Event has ended";
            }
            const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
            return `${daysLeft} days left`;
        }
    }

    function shoeCouponCode(e) {
        e.preventDefault();
        openModal(
            <div className='container'>
                <img src={coupon.logo} className='coupon-modal-img' alt={coupon.merchant_name} />
                <div className='coupon-modal-code'>
                    <span className='coupon-modal-title'>Coupon Code: </span>
                    <span>{coupon.coupon_code}</span>
                </div>
                <a className='coupon-modal-deal' href={coupon.redirect_link} target="_blank" rel="noopener noreferrer">
                    Go to Deal
                </a>
            </div>
        );
    }

    return (
        <a className='Coupon-Container-Home' href={coupon.redirect_link} target="_blank" rel="noopener noreferrer">
            <div className="Image-Container-Home">
                <img
                    ref={imageRef}
                    src={coupon.logo}
                    alt={coupon.merchant_name}
                    onError={handleImageError}
                    className="lazy-load"
                />
                <div className='Discount-Container'>
                    <span>{coupon.discount}</span>
                    <span>OFF</span>
                    <span>{coupon.coupon_type}</span>
                </div>
            </div>
            <div className="Coupon-Desc-Home">
                <p className="Coupon-Cat-Home">{coupon.category.split("|")[0]}</p>
                <p className="Coupon-Title-Home">{coupon.title.split(/code/i)[0]}</p>
                <p>{coupon.merchant_name}</p>
                <p className="Coupon-Time-Home">{calculateRemainingDays(coupon.start_date, coupon.end_date)}</p>
            </div>
            {coupon.coupon_code !== 'None' ?
                <div className="Coupon-CTA-Container" onClick={shoeCouponCode}>
                    <div className="code">
                        <span>{coupon.coupon_code}</span>
                    </div>
                    <div className='hide'>
                    <span>
                        Show Coupon Code
                    </span>
                    </div>
                </div>
                :
                <div className="Coupon-CTA-Container">
                    <button>Get this deal</button>
                </div>
            }
        </a>
    );
};

export default Coupon;