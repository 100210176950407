import React from 'react';
import '../style/privacy-terms.css';

const Contact = () => {
    return (
        <div className="Contact-Container">
            <h1>Contact Us</h1>
            <div className="entry-section">
                <h2>For any question, suggestion or bug report, feel free to contact our professional team! </h2>
                <h2 style={{fontSize: "21px"}}>Just email us to: <a href="mailto:info@couponpotato.com">info@couponpotato.com</a></h2>
            </div>
        </div>
    );
};

export default Contact;
