import React, {useEffect, useState} from 'react';
import '../style/Brand.css';
import Coupon from "./Coupon";
import { useLocation } from 'react-router-dom';
import { ModalProvider, useModal } from './ModalContext';

const Modal = () => {
    const { modalContent, closeModal } = useModal();

    if (!modalContent) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div onClick={closeModal} style={{position: 'absolute', top: '10px', left: '10px', width: 'auto', cursor: 'pointer'}}>X</div>
                {modalContent}
            </div>
        </div>
    );
};

const Brand = () => {
    const location = useLocation();

    const state = location.state;
    const [coupons, setCoupons] = useState(state.coupons);

    const getBrandCoupons = (brand) => {
        const brandCoupons = []
        coupons.forEach((result) => {
            if (result.merchant_name === brand) {
                brandCoupons.push(result);
            }
        });
        return brandCoupons;
    }

    useEffect(() => {
        const brandCoupons = getBrandCoupons(state.brand);
        setCoupons(brandCoupons);

    }, []);

    return (
        <ModalProvider>
            <div className="Home-Container">
            <h1>{state.brand} Coupons</h1>
            <div className="Brand-Coupons-Container">
                {coupons.map((result, index) => (
                    <Coupon key={index} coupon={result}/>
                ))}
            </div>
        </div>
            <Modal/>
        </ModalProvider>
    );
};

export default Brand;
