import React from 'react';
import LoadGif from '../style/load.gif';
import '../style/Slider.css';
import {Swiper, SwiperSlide} from "swiper/react";
import {A11y, Autoplay, Navigation, Pagination, Scrollbar} from "swiper/modules";
const Slider = ({ coupons }) => {
    const imageRef = React.useRef(null);

    const handleImageError = (e) => {
        e.target.onerror = null;
        e.target.src = LoadGif;
    };

    return (
        <div className="Carousel-Container">
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={30}
                slidesPerView={3}
                navigation
                autoplay={{ delay: 2500 }}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 30
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 40
                    },
                }}
            >
                {coupons.slice(0,10).map((result, index) => (
                    <SwiperSlide key={index}>
                        <a className='Coupon-Container' href={result.redirect_link} target="_blank" rel="noopener noreferrer">
                            <div className="Image-Container">
                                <img
                                    ref={imageRef}
                                    src={result.logo}
                                    alt={result.merchant_name}
                                    onError={handleImageError}
                                    className="lazy-load"
                                />
                            </div>
                            <div className="Coupon-Desc">
                                <p>{result.merchant_name}</p>
                                <span className="Coupon-Title">{result.discount.includes('%')? 'Save' : 'Take'} {result.discount} Off</span>
                            </div>
                        </a>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
);
};

export default Slider;