import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import "../style/Home.css"
import Coupon from "./Coupon";
import {getAllCoupons} from "../api/Api";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {isLoading, countryState} from "../api/Atoms";
import {ModalProvider, useModal} from './ModalContext';

const Search = () => {
    const location = useLocation();
    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const setLoadingState = useSetRecoilState(isLoading)
    const country = useRecoilValue(countryState)
    const GetSearchResults = (query, data) => {
        const searchResults = [];
        data.forEach((result) => {
            if (result.title.toLowerCase().includes(query.toLowerCase()) || result.merchant_name.toLowerCase().includes(query.toLowerCase())) {
                searchResults.push(result);
            }
        });
        return searchResults;
    }

    const Modal = () => {
        const {modalContent, closeModal} = useModal();

        if (!modalContent) return null;

        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <div onClick={closeModal}
                         style={{position: 'absolute', top: '10px', left: '10px', width: 'auto', cursor: 'pointer'}}>X
                    </div>
                    {modalContent}
                </div>
            </div>
        );
    };

    useEffect(() => {
        setLoadingState(true);
        const queryParams = new URLSearchParams(location.search);
        setSearchQuery(decodeURIComponent(queryParams.get('q')));
        const searchQuery = decodeURIComponent(queryParams.get('q'));
        getAllCoupons(country).then((data) => {
            setSearchResults(GetSearchResults(searchQuery, data.merchants));
            setLoadingState(false);
        });
    }, [location]);


    return (
        <ModalProvider>
            <div>
                <div className="Search-Container">
                    <h1>Search Results For: {searchQuery}</h1>
                    <span>{searchResults.length} result where found!</span>
                    <div className="Search-Result-Container">
                        {searchResults.map((result, index) => (
                            <Coupon key={index} coupon={result}/>
                        ))}
                    </div>
                </div>
            </div>
            <Modal/>
        </ModalProvider>
    )
}
export default Search;
